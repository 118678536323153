var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Address Information "),(_vm.addressData.isMonitoring)?_c('MonitoringButton'):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [(!_vm.hasTxs && !_vm.addressDataLoading)?_c('div',{staticClass:"wallet-address-wrap pa-2"},[_vm._v(" There are no transactions for this address ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[_c('InfoBlock',{staticClass:"mb-4 mr-3",attrs:{"flex-second-column-two":"","inline-view":"","label":"Wallet address","loading":_vm.addressDataLoading,"value":("" + ((_vm.addressData && _vm.addressData.address) || _vm.address))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Type of the address","loading":_vm.addressDataLoading,"value":"Token Contract"}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Token","loading":_vm.addressDataLoading,"value":_vm.selectedToken.symbol},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.selectedToken && _vm.selectedToken.icon)?_c('GlImg',{attrs:{"src-val":_vm.selectedToken.icon}}):_vm._e()]},proxy:true}])}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Token Balance","loading":_vm.addressDataLoading,"value":_vm.addressData &&
              ((_vm.toComaSeparate(String(_vm.restrictNumberAfterComma(_vm.addressData.balance.amount, 8)))) + " " + (_vm.selectedToken.symbol))}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Number of TXS","loading":_vm.addressDataLoading,"value":(_vm.addressData && _vm.toComaSeparate(String(_vm.addressData.txCount))) || '0'}}),_c('InfoBlock',{staticClass:"mb-4",attrs:{"flex-second-column-two":"","inline-view":"","label":"Total tx sent","loading":_vm.addressDataLoading,"value":(_vm.addressData && _vm.toComaSeparate(String(_vm.addressData.txOutputsCount))) || '0'}}),_c('InfoBlock',{attrs:{"flex-second-column-two":"","inline-view":"","label":"Total tx received","loading":_vm.addressDataLoading,"value":(_vm.addressData && _vm.toComaSeparate(String(_vm.addressData.txInputsCount))) || '0'}})],1)]},proxy:true}])}),_c('Section',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" Owner Information ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.isOwnerExist(_vm.addressData))?_c('div',[_c('ReportSectionBody',{attrs:{"data":_vm.addressData,"data-description":_vm.addressData.description,"label-tag":"Extra context tags towards the classification of the requested address","label-top":"Owner / Contract Name","label-type":"Type of entity that owns requested address","owner-name":(((_vm.addressData && _vm.addressData.owner) ||
            (_vm.contractData && _vm.contractData.owner) ||
            '--') + " / " + ((_vm.addressData &&
              _vm.addressData.contractInfo &&
              _vm.addressData.contractInfo.name) ||
            (_vm.contractData &&
              _vm.contractData.contractInfo &&
              _vm.contractData.contractInfo.name) ||
            '--')),"tag-meta-data":_vm.addressData.meta,"type-data":_vm.addressData.type}}),_c('hr',{staticClass:"mt-2 mb-2 pale-grey-border"}),_c('EthContractEnsBlock',{attrs:{"address-data":_vm.addressData,"contract-data":_vm.contractData}})],1):_c('div',{staticClass:"pa-2"},[_vm._v(" Not identified ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }